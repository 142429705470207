<template>
  <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="notificationsDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Training
            </a>
            <ul class="dropdown-menu" aria-labelledby="notificationsDropdown">
              <li><a class="dropdown-item" href="/my-enrolled-courses/">Training</a></li>
              <li><a class="dropdown-item" href="/my-enrolled-courses/course-offerings/">Training Courses (LMS)</a></li>
              <li><a class="dropdown-item" href="#">Training 3</a></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="" id="cartDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Tools & Resources 
            </a>
            <ul class="dropdown-menu" aria-labelledby="cartDropdown">
              <li><a class="dropdown-item" href="https://eosone-dev.azurewebsites.net/">Online Tools</a></li>
              <li><a class="dropdown-item" href="#">Tools & Resources  2</a></li>
              <li><a class="dropdown-item" href="#">Tools & Resources  3</a></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="profileDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Community
            </a>
            <ul class="dropdown-menu" aria-labelledby="profileDropdown">
              <li><a class="dropdown-item" href="#">Community 1</a></li>
              <li><a class="dropdown-item" href="#">Community 2</a></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="profileDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Events
            </a>
            <ul class="dropdown-menu" aria-labelledby="profileDropdown">
              <li><a class="dropdown-item" href="#">Events 1</a></li>
              <li><a class="dropdown-item" href="#">Events 2</a></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="profileDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Store
            </a>
            <ul class="dropdown-menu" aria-labelledby="profileDropdown">
              <li><a class="dropdown-item" href="#">Store 1</a></li>
              <li><a class="dropdown-item" href="#">Store 2</a></li>
            </ul>
          </li>
        </ul>
      </div>
</template>

<script setup>

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
