<template>
  <a class="navbar-brand" href="#">
        <img src="../assets/eos-logo.png" alt="Logo" width="100" height="39" class="d-inline-block align-text-top">
        <span class="tag-line">All things EOS<sup>®</sup> all in one place. </span>
      </a>
</template>

<script setup>

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
