<template>
  <HeaderContainer />
</template>

<script setup>
import HeaderContainer from '@/components/Header-Container.vue';

console.warn("HeaderContainer: ", HeaderContainer);

</script>

